<template>
  <div>
    <div class="w-onboarding-stepone mx-auto">
      <img src="@/assets/img/logo.svg" width="130" class="mb-2" />
    </div>
    <div class="bg-white w-1/2 flex flex-col max-w-onboarding-2 mx-auto">
      <div class="px-55 pt-45 mb-7">
        <p class="font-bold text-grey-dark-2 text-l mb-1.5">
          Account Information
        </p>
        <p class="text-md text-grey-dark-1 mb-6">
          Please verify your name and then create a password to access your
          account.
        </p>
        <p class="font-medium text-sm-2 text-grey-light mb-0.4">
          First and Last Name
        </p>
        <p class="font-bold text-xl text-grey-dark-1 mb-0.4">
          {{ userName }}
        </p>
        <p class="text-md text-grey-dark-1 mb-3">
          {{ infoMessage }}
        </p>
        <InputField
          class="mb-2"
          label="Email"
          type="text"
          disabled
          :propValue="this.email"
          data-testid="email-field"
        />
        <PasswordForm
          :setValidity="setValidity"
          :onChange="changePass"
          data-testid="password-form"
        />
      </div>
      <div class="pb-4">
        <div class="flex justify-center">
          <Button
            text="Save and Continue"
            type="primary"
            size="large"
            class="w-a-full"
            :onClick="onClick"
            :disabled="!validPassword"
            data-testid="save-button"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import jwt_decode from "jwt-decode";
import Button from "../../../components/Button/Button";
import PasswordForm from "../../../components/PasswordValidation/PasswordForm";
export default {
  name: "SecondStep",
  components: {
    Button,
    PasswordForm,
  },
  props: ["role"],
  data: () => ({
    validPassword: false,
    code: null,
    email: null,
    password: null,
    userName: null,
  }),
  computed: {
    isAdmin() {
      return this.role === "companyadmin";
    },
    infoMessage() {
      switch (this.role) {
        case "companyadmin":
          return "If you would like your name changed, please contact ISM with this request.";
        case "selleradmin":
          return "If you would like your name changed, please contact your organization administrator directly.";
        default:
          return "If this is not you please contact your company's admin to request another invitation.";
      }
    },
  },
  mounted() {
    const decodedToken = atob(unescape(this.$route.query.token));
    let jwt = jwt_decode(decodedToken);
    this.code = decodedToken;
    this.email = jwt.email;
    this.userName = jwt.firstName + " " + jwt.lastName;
  },
  methods: {
    ...mapActions({
      setStep: "onboarding/setStep",
      confirmAccount: "auth/confirmAccount",
    }),
    async onClick() {
      await this.confirmAccount({
        email: this.email,
        password: this.password,
        token: this.code,
        noRedirect: this.isAdmin,
      });
      if (this.isAdmin) this.setStep({ step: 2 });
    },
    setValidity(value) {
      this.validPassword = value;
    },
    changePass(val) {
      this.password = val;
    },
  },
};
</script>

<style></style>
